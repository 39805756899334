@import "./theme/margin.scss";
@import "./theme/padding.scss";
@import "./theme/font-size.scss";

body, html, #root{
    height: 100%;
}
.btn-outline-warning{
    --bs-btn-border-color: #e5780b;
    --bs-btn-color: #e5780b;
    --bs-btn-hover-bg: #e5780b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-border-color: #d6700a;
}
.alert-soft-warning{
    color: #e5780b;
    border: 1px solid #e5780b;
}

.bg-main {
    background-color: #f9fafa;
}
.footer {
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    &.alternative{
        position: relative;
        margin-left: 0;
    }
}
.form-label {
    margin-bottom: 4px;
    text-transform: uppercase;
}
.btn {
    text-transform: uppercase;
}
.btn.btn-link:disabled {
    background-color: transparent !important;
    color: #777777 !important;
    font-weight: 400 !important;
    text-decoration: none !important;
}
.navbar-brand-logo {
    min-width: 110px;
    max-width: 110px;
    width: 110px;
}
.font-12 {
    font-size: 0.75rem;
}
.font-10 {
    font-size: 0.625rem;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

h1 {
    line-height: 72px;
    font-size: 48px;
}
h2 {
    line-height: 56px;
    font-size: 40px;
}
h3 {
    line-height: 48px;
    font-size: 32px;
}
h4 {
    line-height: 32px;
    font-size: 24px;
}
h5 {
    line-height: 32px;
    font-size: 20px;
}
p.large,
h6 {
    font-size: 16px;
    line-height: 24px;
}
p {
    line-height: 24px;
}

.cursor-pointer {
    cursor: pointer;
}
.border-top {
    border-top: 1px solid #e6e6e6;
}
.border-bottom {
    border-bottom: 1px solid #e6e6e6;
}

.table thead tr th,
.table tbody tr td,
.table tbody tr th {
    padding: 1.25rem;
}

.btn-outline-trash {
    color: #777777;
}
.btn-outline-edit {
    color: #0082f2;
}
.card-table {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
}

.input-group-borderless {
    border: none;
}
.modal-body {
    padding: 2rem;
}

.form-check-input.form-check-info:checked {
    background-color: #0082f2;
}

.tom-select-custom .ts-wrapper.form-select .ts-control,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple {
    padding: 0.5rem 1rem;
}

.form-select-custom__control {
    min-height: calc(1.5em + 1.625rem) !important;
    border: 1px solid #dbdbdb !important;
    border-radius: 4px !important;
    // padding: 0.5rem 1rem;
    color: #3d4042;
    &.form-select-custom__control--is-focused {
        box-shadow: 0 0 1rem 0 rgb(140 152 164 / 25%) !important;
    }
    .form-select-custom__multi-value {
        background: #f3f4f8 !important;
        border-radius: 8px !important;

        .form-select-custom__multi-value__label {
            color: #39589d !important;
            // font-weight: 600 !important;
            font-size: 12px !important;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 8px;
        }
        .form-select-custom__multi-value__remove {
            color: #aec2d3;
            padding-right: 8px;
            padding-left: 8px;
            border-radius: 8px;
            &:hover {
                background-color: #f3f4f8;
                color: #39589d;
            }
        }
    }
    .form-select-custom__value-container{
        text-wrap: nowrap;
    }
}
.form-select-custom__menu {
    width: auto;
    .form-select-custom__option {
        color: #323e45 !important;
        cursor: pointer;
        &.form-select-custom__option--is-focused {
            color: #39589d !important;
            // font-weight: 600;
            background: #f3f4f8 !important;
            background-color: rgba(189, 197, 209, 0.2) !important;
        }
        &.form-select-custom__option--is-selected {
            background: #f3f4f8 !important;
            color: #39589d !important;
            // font-weight: 600;
        }
    }
}

.form-select-custom.is-invalid {
    .form-select-custom__control {
        border-color: #d82525 !important;
        &:focus,
        &.form-select-custom__control--is-focused {
            box-shadow: 0 0 1rem 0 rgb(216 37 37 / 25%) !important;
        }
    }
}

.form-select-custom__placeholder {
    color: #8c98a4 !important;
    opacity: 1 !important;
}

.form-select-custom__option--is-selected {
    background-color: #7991c6 !important;
}

.form-select-custom-flags {
    .form-select-custom__control {
        width: max-content;
    }
    .form-select-custom__menu {
        width: auto;
    }
}

.btn-outline-primary {
    background-color: #fff;
}

.projecter-alert-container {
    z-index: 99999;
    position: fixed;
    top: 79px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    .custom-alert-success {
        background: #f4fff6;
        border: 1px solid #b7c9b2;
        border-radius: 4px;

        .title {
            color: #187900;
        }
        .desc {
            color: #608157;
        }
    }
    
    .custom-alert-error {
        
        border-radius: 4px;
        border: 1px solid  #CA0606;
        background: #FFF4F4;

        /* Card */
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

        .title {
            color: #790000;
        }
        .desc {
            color: #790000;
        }
    }
}
.nowrap {
    white-space: nowrap;
}

.drag-drop {
    text-align: center;
    background: #fafafa;
    border: 1px dashed #dbdbdb;
    padding-top: 32px;
    padding-bottom: 32px;

    &.drop-enter {
        background: #f8fafd;
    }

    &.is-invalid {
        border-color: #d82525;
    }
}

.card-detail {
    background-color: #fff;
    box-shadow: -1px 4px 4px #e6e6e6;
    padding: 24px;
}

.splitted-content-mini.offcanvas,
.splitted-content-small.offcanvas {
    z-index: 0;
}

.icon-version {
    width: 14px;
    height: 14px;
    border: 1px dashed #39589d;
    border-radius: 100%;
    .icon-version-container {
        background: #39589d;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin: 0 auto;
        margin-top: 2px;
    }
}

.divider-center-full {
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 1px;
}

.avatar-force-w {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
}

.nav-icon {
    color: #aec2d3;
    opacity: 1;
}

.active {
    .nav-icon {
        color: #39589d;
        opacity: 1;
    }
}

.form-check-label {
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--projecter-color-title);
}

.height-50 {
    height: 50px;
}

.ql-container{
    height: 15rem !important;
    // max-height: 15rem;
    // overflow-y: scroll;
}


.preview-open{
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
}
.preview{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(30,30,30,.9),#000 1810%);
    z-index: 1000000000;

    .preview-navbar{
        height: 45px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .preview-toolbar{
            position: absolute;
            z-index: 3;
            right: 0;
            top: 0;
            height: 100%;
            display: flex;
            background: rgba(35,35,35,.65);
            .preview-toolbar-button{
                height: 100%;
                width: 45px;
                cursor: pointer;
            }
            .preview-flex-centered{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        
    }
    .preview-content{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .preview-video{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            video{
                max-height: 90%;
                max-width: 90%;
            }
        }
        .preview-img{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-height: 90%;
                max-width: 90%;
            }
        }
        .preview-pdf{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            // align-items: center;
            overflow-y: scroll;
        }
        .preview-loader{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .preview-html{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            iframe{
                width: 80%;
                height: 80%;
            }
        }
    }
}


.audio-player-container{
    .audio-player-btn{
        cursor: pointer;
        background-color: #ECF0F8;
        border-radius: 100%;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        position: relative;
        // padding: 16px;
        i{
            font-size: 24px;
            color: #7991C6;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .progress{
        background-color: #ECECEC;
    }
    p{
        margin-left: 16px;
        font-weight: 600;
        &:first-of-type{
            margin-right: 16px;
        }
    }
}

.poster-preview{
    height: 100px;
    width: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    cursor: pointer;
}


// input.form-control:read-only{
//     border: none;
//     box-shadow: none;
//     padding-left: 0;
// }

//Dashboard
.flex-between-center {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.icon-rounded {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EEF4F9;
    color: #0082F2;
    font-size: 16px;
}

.icon-item {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5e6e82;
    transition: all .2s ease-in-out;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    font-size: .875rem;
}

.icon-item.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
}

.bg-soft-blue {
    background-color: #EEF4F9;
}

.text-blue {
    color: #0082F2;
}

.bg-soft-purple {
    background-color: #F0EEF9;
}

.text-purple {
    color: #640F9F;
}

.bg-soft-pink {
    background-color: #F9EEF9;
}

.text-pink {
    color: #FF40B4;
}

@media (min-width: 992px) {
    .border-lg-end {
        border-right: 1px solid #E6E6E6 !important;
    }
}


.template-preview{
    // background: url('/assets/img/preview-bg.png');
    // background-size: 366.5px 666px;
    background-color: #e5ddd5;
    position: relative;
    z-index: 0;
    padding: 24px;
    &::before{
        background-image: url('/assets/img/preview-bg.png');
        background-size: 366.5px 666px;
        content: '';
        height: 100%;
        left: 0;
        opacity: .06;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .template-preview-container{
        position: relative;
        background-color: #fff;
        padding: 8px;
        border-radius: 7.5px;
        border-top-left-radius: 0;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
        min-height: 20px;
        position: relative;
        word-wrap: break-word;
        &::after{
            background: url('/assets/img/caret.png') 50% 50% no-repeat;
            background-size: contain;
            content: '';
            height: 19px;
            left: -12px;
            position: absolute;
            top: 0;
            width: 12px;
        }
    }

    .template-preview-btn{
        position: relative;
        background-color: #fff;
        padding: 8px;
        border-radius: 7.5px;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
        min-height: 20px;
        position: relative;
        text-align: center;
        color: rgb(0,117,255);
        margin-bottom: 4px;
    }
}

.btn-link-projecter{
    color: #0082F2;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: inherit;
    text-decoration: underline;
}


.list-chat-container{
    border-right:  1px solid #E6E6E6;
    height: 100%;
    // background-color: #fff;
    // border-radius: 4px;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    
    .chat-item{
        border-bottom: 1px solid #ECECEC;
        padding: 16px;
        cursor: pointer;
        // &:last-child{
        //     border-bottom: none;
        // }
        &:hover,
        &.active{
            background-color: #f3f4f8;
        }
        h4{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #272727;
        }
        .date{
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #777777;
        }
    }
}

.h-100-scroll{
    height: 100%;
    overflow: scroll;
}
.messages{
    background: #F5F6F6;
    display: flex;
    flex-direction: column;
    
    .messages-container{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // flex-direction: column-reverse;
        margin: 16px;
        .message-item{
            background: #0082F2;
            box-shadow: 0px 1px 0px #D8D8D8;
            
            color: #FFFFFF;
            align-self: flex-end;
    
            border-radius: 8px;
            padding: 16px;
            max-width: 75%;

            margin-bottom: 16px;

            a{
                color: #fff;
                text-decoration: underline;
            }
    
            & > p{
                text-align: right;
                color: #C1E2FF;
            }
    
            &.left{
                background: #FFFFFF;
                box-shadow: 0px 1px 0px #D8D8D8;
                color: #3D4042;
                align-self: flex-start;
    
                a{
                    color: #3D4042;
                    text-decoration: underline;
                }

                & > p{
                    text-align: right;
                    color: #777777;
                }
            }
            &.sticker{
                background: transparent;
                box-shadow: none;
                color: #3D4042;
                align-self: flex-start;
    
                & > p{
                    text-align: right;
                    color: #777777;
                }
            }


            &:last-child{
                // margin-bottom: 0px;
            }

            .icon{
                background-color: #ECF0F8;
                border-radius: 100%;
                height: 32px;
                width: 32px;
                .bi{
                    color: #7991C6;
                }
            }


            .message-multimedia{
                width: 250px;
                h6{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #272727;
                }
                .btn-link{
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    color: #7991C6;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }

                &.audio{
                    .bi{
                        font-size: 40px;
                        cursor: pointer;
                    }
                    .progress{
                        background-color: #ECECEC;
                    }
                }

                &.map{
                    height: 100px;
                    width: 100px;
                }
                &.sticker{
                    height: 150px;
                    width: 150px;
                    img{
                        width: 100%;
                    }
                }
            }

            &.no-session{
                border-radius: 4px;
                background:  #E8F0F0;
                box-shadow: 0px 1px 0px 0px #C5DADA;
                color: #272727;
                margin: 0 auto;
            }

        }
    }
}


.messages-header{
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #ECECEC;
    padding: 16px;
    border-right:  1px solid #E6E6E6;
    &:last-child{
        border-right: none;
    }
    h4{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #272727;
    }
    h3{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: #272727;
    }
}


.card-mini{
    text-align: center;
    width: fit-content;
    margin-right: 16px;
    margin-left: 16px;
    h6{
        font-weight: 400;
        font-size: 12px;
        // line-height: 16px;
        color: #777777;
    }
    h3{
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #323E45;
    }
}


.btn-custom-link{
    text-decoration: underline;
    color: #0082F2;
    font-weight: 600;
    line-height: 20px;
    text-transform: none;
    &:hover{
        color: #0067c1;
    }
}

.projecter-tooltip{
    width: fit-content;
    display: inline-block;

}

.color-icon{
    color: #AEC2D3;
}

.daterangepicker{
    z-index: 9999;
}

.offcanvas-projecter{
    --bs-offcanvas-width: 876px;
}

.border-botton{
    border-bottom: 1px solid #E6E6E6;
}

.btn-outline-primary{
    &:hover, &:visited, &:active, &:focus{
        background: #F3F4F8;
        color: #39589D;
        border-color: #dbdbdb;

        &::after{
            background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
        }
        
    }

    &.dropdown-toggle.show.dropdown-toggle::after{
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
    }
}

.badge-image{
    background-color: #F9F8DD;
    color: #323e45;
}
.badge-video{
    background-color: #E4E1F7;
    color: #3D2CA6;
}
.badge-link{
    background-color: #F0F0F0;
    color: #777777;
}
.badge-pdf{
    background-color: #FEE0E0;
    color: #D90000;
}
.badge-audio{
    background-color: #0082F2;
    color: #E0F0FE;
}
.badge-form{
    background-color: #DDF9EF;
    color: #09BB7B;
}
.badge-poll{
    background-color: #F5E1F7;
    color: #A42CA6;
}

.title-secondary{
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #323E45;
}


.step-divider{
    &.last::after{
        display: none;
    }
}


.comments-material{
    h1{
        font-size: 2em;    
    }
    h2{
        font-size: 1.5em;    
    }
    h3{
        font-size: 1.17em;    
    }
    h4{
        font-size: 1em;    
    }
    h5{
        font-size: 0.83em;    
    }
    h6{
        font-size: 0.67em;    
    }
}

.navbar .dropdown-menu{
    margin-top: initial ;
    border-top: initial ;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #DBDBDB;
    .dropdown-item{
        border-radius: 0;
        border-bottom: 1px solid #F5F5F5;
        margin: 0;
        &:last-of-type{
            border-bottom: none;
        }
    }
}


.preview-wa{
    border-radius: 4px;
    background: #F5F5F5;
    padding: 16px;
    .preview-wa-message{
        background-color: #fff;
        border-radius: 4px;
        padding: 16px;
        .wa-title{
            color: #323E45;
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 16px;
        }
        .wa-body{
            color:  #323E45;
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .wa-footer{
            color: #747474;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 16px;
        }
    }
    .preview-wa-button{
        background-color: #fff;
        border-radius: 4px;
        text-align: center;
        color: #00A3FF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 8px ;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
.separator-left{
    border-left: 1px solid #E6E6E6;
}

.var-file-container{
    border-radius: 4px;
    background: #FAFAFA;
    padding: 16px;
    color: #323E45;
}


.var-container{
    display: flex;
    flex-wrap: wrap;
    .var-item{
        border-radius: 8px;
        background: #F3F4F8;
        color: #39589D;
        padding: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;
    }
}

.text-initial{
    text-transform: initial;
}

.h-100{
    height: 100%;
}

.alert-blue{
    border-radius: 4px;
    border: 1px solid #C0CEFF;
    background: #F4F7FF;

    /* Card */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    color: #0082F2;
    font-weight: 600;
    padding: 16px;

}

.btn-simple{
    color: #39589D;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.48px;
    text-transform: uppercase;
    &:hover{
        color: #283f70;
    }
}

.btn-link-alternative{
    font-weight: normal !important;
    color: #0082F2;
    &:hover{
        color: #0082F2;
        font-weight: 500 !important;
    }
}