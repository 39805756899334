
.p-b-1 {
    padding-bottom: 1px !important;
}        
.p-t-1 {
    padding-top: 1px !important;
}        
.p-l-1 {
    padding-left: 1px !important;
}        
.p-r-1 {
    padding-right: 1px !important;
}        

.p-b-2 {
    padding-bottom: 2px !important;
}        
.p-t-2 {
    padding-top: 2px !important;
}        
.p-l-2 {
    padding-left: 2px !important;
}        
.p-r-2 {
    padding-right: 2px !important;
}        

.p-b-3 {
    padding-bottom: 3px !important;
}        
.p-t-3 {
    padding-top: 3px !important;
}        
.p-l-3 {
    padding-left: 3px !important;
}        
.p-r-3 {
    padding-right: 3px !important;
}        

.p-b-4 {
    padding-bottom: 4px !important;
}        
.p-t-4 {
    padding-top: 4px !important;
}        
.p-l-4 {
    padding-left: 4px !important;
}        
.p-r-4 {
    padding-right: 4px !important;
}        

.p-b-5 {
    padding-bottom: 5px !important;
}        
.p-t-5 {
    padding-top: 5px !important;
}        
.p-l-5 {
    padding-left: 5px !important;
}        
.p-r-5 {
    padding-right: 5px !important;
}        

.p-b-6 {
    padding-bottom: 6px !important;
}        
.p-t-6 {
    padding-top: 6px !important;
}        
.p-l-6 {
    padding-left: 6px !important;
}        
.p-r-6 {
    padding-right: 6px !important;
}        

.p-b-7 {
    padding-bottom: 7px !important;
}        
.p-t-7 {
    padding-top: 7px !important;
}        
.p-l-7 {
    padding-left: 7px !important;
}        
.p-r-7 {
    padding-right: 7px !important;
}        

.p-b-8 {
    padding-bottom: 8px !important;
}        
.p-t-8 {
    padding-top: 8px !important;
}        
.p-l-8 {
    padding-left: 8px !important;
}        
.p-r-8 {
    padding-right: 8px !important;
}        

.p-b-9 {
    padding-bottom: 9px !important;
}        
.p-t-9 {
    padding-top: 9px !important;
}        
.p-l-9 {
    padding-left: 9px !important;
}        
.p-r-9 {
    padding-right: 9px !important;
}        

.p-b-10 {
    padding-bottom: 10px !important;
}        
.p-t-10 {
    padding-top: 10px !important;
}        
.p-l-10 {
    padding-left: 10px !important;
}        
.p-r-10 {
    padding-right: 10px !important;
}        

.p-b-11 {
    padding-bottom: 11px !important;
}        
.p-t-11 {
    padding-top: 11px !important;
}        
.p-l-11 {
    padding-left: 11px !important;
}        
.p-r-11 {
    padding-right: 11px !important;
}        

.p-b-12 {
    padding-bottom: 12px !important;
}        
.p-t-12 {
    padding-top: 12px !important;
}        
.p-l-12 {
    padding-left: 12px !important;
}        
.p-r-12 {
    padding-right: 12px !important;
}        

.p-b-13 {
    padding-bottom: 13px !important;
}        
.p-t-13 {
    padding-top: 13px !important;
}        
.p-l-13 {
    padding-left: 13px !important;
}        
.p-r-13 {
    padding-right: 13px !important;
}        

.p-b-14 {
    padding-bottom: 14px !important;
}        
.p-t-14 {
    padding-top: 14px !important;
}        
.p-l-14 {
    padding-left: 14px !important;
}        
.p-r-14 {
    padding-right: 14px !important;
}        

.p-b-15 {
    padding-bottom: 15px !important;
}        
.p-t-15 {
    padding-top: 15px !important;
}        
.p-l-15 {
    padding-left: 15px !important;
}        
.p-r-15 {
    padding-right: 15px !important;
}        

.p-b-16 {
    padding-bottom: 16px !important;
}        
.p-t-16 {
    padding-top: 16px !important;
}        
.p-l-16 {
    padding-left: 16px !important;
}        
.p-r-16 {
    padding-right: 16px !important;
}        

.p-b-17 {
    padding-bottom: 17px !important;
}        
.p-t-17 {
    padding-top: 17px !important;
}        
.p-l-17 {
    padding-left: 17px !important;
}        
.p-r-17 {
    padding-right: 17px !important;
}        

.p-b-18 {
    padding-bottom: 18px !important;
}        
.p-t-18 {
    padding-top: 18px !important;
}        
.p-l-18 {
    padding-left: 18px !important;
}        
.p-r-18 {
    padding-right: 18px !important;
}        

.p-b-19 {
    padding-bottom: 19px !important;
}        
.p-t-19 {
    padding-top: 19px !important;
}        
.p-l-19 {
    padding-left: 19px !important;
}        
.p-r-19 {
    padding-right: 19px !important;
}        

.p-b-20 {
    padding-bottom: 20px !important;
}        
.p-t-20 {
    padding-top: 20px !important;
}        
.p-l-20 {
    padding-left: 20px !important;
}        
.p-r-20 {
    padding-right: 20px !important;
}        

.p-b-21 {
    padding-bottom: 21px !important;
}        
.p-t-21 {
    padding-top: 21px !important;
}        
.p-l-21 {
    padding-left: 21px !important;
}        
.p-r-21 {
    padding-right: 21px !important;
}        

.p-b-22 {
    padding-bottom: 22px !important;
}        
.p-t-22 {
    padding-top: 22px !important;
}        
.p-l-22 {
    padding-left: 22px !important;
}        
.p-r-22 {
    padding-right: 22px !important;
}        

.p-b-23 {
    padding-bottom: 23px !important;
}        
.p-t-23 {
    padding-top: 23px !important;
}        
.p-l-23 {
    padding-left: 23px !important;
}        
.p-r-23 {
    padding-right: 23px !important;
}        

.p-b-24 {
    padding-bottom: 24px !important;
}        
.p-t-24 {
    padding-top: 24px !important;
}        
.p-l-24 {
    padding-left: 24px !important;
}        
.p-r-24 {
    padding-right: 24px !important;
}        

.p-b-25 {
    padding-bottom: 25px !important;
}        
.p-t-25 {
    padding-top: 25px !important;
}        
.p-l-25 {
    padding-left: 25px !important;
}        
.p-r-25 {
    padding-right: 25px !important;
}        

.p-b-26 {
    padding-bottom: 26px !important;
}        
.p-t-26 {
    padding-top: 26px !important;
}        
.p-l-26 {
    padding-left: 26px !important;
}        
.p-r-26 {
    padding-right: 26px !important;
}        

.p-b-27 {
    padding-bottom: 27px !important;
}        
.p-t-27 {
    padding-top: 27px !important;
}        
.p-l-27 {
    padding-left: 27px !important;
}        
.p-r-27 {
    padding-right: 27px !important;
}        

.p-b-28 {
    padding-bottom: 28px !important;
}        
.p-t-28 {
    padding-top: 28px !important;
}        
.p-l-28 {
    padding-left: 28px !important;
}        
.p-r-28 {
    padding-right: 28px !important;
}        

.p-b-29 {
    padding-bottom: 29px !important;
}        
.p-t-29 {
    padding-top: 29px !important;
}        
.p-l-29 {
    padding-left: 29px !important;
}        
.p-r-29 {
    padding-right: 29px !important;
}        

.p-b-30 {
    padding-bottom: 30px !important;
}        
.p-t-30 {
    padding-top: 30px !important;
}        
.p-l-30 {
    padding-left: 30px !important;
}        
.p-r-30 {
    padding-right: 30px !important;
}        

.p-b-31 {
    padding-bottom: 31px !important;
}        
.p-t-31 {
    padding-top: 31px !important;
}        
.p-l-31 {
    padding-left: 31px !important;
}        
.p-r-31 {
    padding-right: 31px !important;
}        

.p-b-32 {
    padding-bottom: 32px !important;
}        
.p-t-32 {
    padding-top: 32px !important;
}        
.p-l-32 {
    padding-left: 32px !important;
}        
.p-r-32 {
    padding-right: 32px !important;
}        

.p-b-33 {
    padding-bottom: 33px !important;
}        
.p-t-33 {
    padding-top: 33px !important;
}        
.p-l-33 {
    padding-left: 33px !important;
}        
.p-r-33 {
    padding-right: 33px !important;
}        

.p-b-34 {
    padding-bottom: 34px !important;
}        
.p-t-34 {
    padding-top: 34px !important;
}        
.p-l-34 {
    padding-left: 34px !important;
}        
.p-r-34 {
    padding-right: 34px !important;
}        

.p-b-35 {
    padding-bottom: 35px !important;
}        
.p-t-35 {
    padding-top: 35px !important;
}        
.p-l-35 {
    padding-left: 35px !important;
}        
.p-r-35 {
    padding-right: 35px !important;
}        

.p-b-36 {
    padding-bottom: 36px !important;
}        
.p-t-36 {
    padding-top: 36px !important;
}        
.p-l-36 {
    padding-left: 36px !important;
}        
.p-r-36 {
    padding-right: 36px !important;
}        

.p-b-37 {
    padding-bottom: 37px !important;
}        
.p-t-37 {
    padding-top: 37px !important;
}        
.p-l-37 {
    padding-left: 37px !important;
}        
.p-r-37 {
    padding-right: 37px !important;
}        

.p-b-38 {
    padding-bottom: 38px !important;
}        
.p-t-38 {
    padding-top: 38px !important;
}        
.p-l-38 {
    padding-left: 38px !important;
}        
.p-r-38 {
    padding-right: 38px !important;
}        

.p-b-39 {
    padding-bottom: 39px !important;
}        
.p-t-39 {
    padding-top: 39px !important;
}        
.p-l-39 {
    padding-left: 39px !important;
}        
.p-r-39 {
    padding-right: 39px !important;
}        

.p-b-40 {
    padding-bottom: 40px !important;
}        
.p-t-40 {
    padding-top: 40px !important;
}        
.p-l-40 {
    padding-left: 40px !important;
}        
.p-r-40 {
    padding-right: 40px !important;
}        

.p-b-41 {
    padding-bottom: 41px !important;
}        
.p-t-41 {
    padding-top: 41px !important;
}        
.p-l-41 {
    padding-left: 41px !important;
}        
.p-r-41 {
    padding-right: 41px !important;
}        

.p-b-42 {
    padding-bottom: 42px !important;
}        
.p-t-42 {
    padding-top: 42px !important;
}        
.p-l-42 {
    padding-left: 42px !important;
}        
.p-r-42 {
    padding-right: 42px !important;
}        

.p-b-43 {
    padding-bottom: 43px !important;
}        
.p-t-43 {
    padding-top: 43px !important;
}        
.p-l-43 {
    padding-left: 43px !important;
}        
.p-r-43 {
    padding-right: 43px !important;
}        

.p-b-44 {
    padding-bottom: 44px !important;
}        
.p-t-44 {
    padding-top: 44px !important;
}        
.p-l-44 {
    padding-left: 44px !important;
}        
.p-r-44 {
    padding-right: 44px !important;
}        

.p-b-45 {
    padding-bottom: 45px !important;
}        
.p-t-45 {
    padding-top: 45px !important;
}        
.p-l-45 {
    padding-left: 45px !important;
}        
.p-r-45 {
    padding-right: 45px !important;
}        

.p-b-46 {
    padding-bottom: 46px !important;
}        
.p-t-46 {
    padding-top: 46px !important;
}        
.p-l-46 {
    padding-left: 46px !important;
}        
.p-r-46 {
    padding-right: 46px !important;
}        

.p-b-47 {
    padding-bottom: 47px !important;
}        
.p-t-47 {
    padding-top: 47px !important;
}        
.p-l-47 {
    padding-left: 47px !important;
}        
.p-r-47 {
    padding-right: 47px !important;
}        

.p-b-48 {
    padding-bottom: 48px !important;
}        
.p-t-48 {
    padding-top: 48px !important;
}        
.p-l-48 {
    padding-left: 48px !important;
}        
.p-r-48 {
    padding-right: 48px !important;
}        

.p-b-49 {
    padding-bottom: 49px !important;
}        
.p-t-49 {
    padding-top: 49px !important;
}        
.p-l-49 {
    padding-left: 49px !important;
}        
.p-r-49 {
    padding-right: 49px !important;
}        

.p-b-50 {
    padding-bottom: 50px !important;
}        
.p-t-50 {
    padding-top: 50px !important;
}        
.p-l-50 {
    padding-left: 50px !important;
}        
.p-r-50 {
    padding-right: 50px !important;
}        
