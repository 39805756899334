.font-12{
    font-size: 12px !important;
    line-height: 12*1.4px !important;
}
.font-13{
    font-size: 13px !important;
    line-height: 13*1.4px !important;
}
.font-14{
    font-size: 14px !important;
    line-height: 14*1.4px !important;
}
.font-15{
    font-size: 15px !important;
    line-height: 15*1.4px !important;
}
.font-16{
    font-size: 16px !important;
    line-height: 16*1.4px !important;
}
.font-17{
    font-size: 17px !important;
    line-height: 17*1.4px !important;
}
.font-18{
    font-size: 18px !important;
    line-height: 18*1.4px !important;
}
.font-19{
    font-size: 19px !important;
    line-height: 19*1.4px !important;
}
.font-20{
    font-size: 20px !important;
    line-height: 20*1.4px !important;
}
.font-21{
    font-size: 21px !important;
    line-height: 21*1.4px !important;
}
.font-22{
    font-size: 22px !important;
    line-height: 22*1.4px !important;
}
.font-23{
    font-size: 23px !important;
    line-height: 23*1.4px !important;
}
.font-24{
    font-size: 24px !important;
    line-height: 24*1.4px !important;
}